<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="bg-img-hero position-relative overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <img class="img-fluid mb-5" src="@/assets/img/products/openapihub-logo.png" style="max-width:15rem">
          <h1 class="mb-3">The Leading Open API Directory and Ecosystem</h1>
          <p class="lead">Connect, Learn, and Thrive with the OpenAPIHub API Community.</p>
          <a class="btn btn-primary transition-3d-hover mt-3" href="https://www.openapihub.com/" target="_blank">Get Started <i class="fas fa-external-link-alt ml-1" /></a>
        </div>
        <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/api-network.svg" alt="OpenAPIHub">
          </div>
        </div>
        <!-- <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/fiberconnect/fiber-hero-img.png" alt="FiberConnect Portals">
          </div>
        </div> -->
      </div>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2 space-lg-3">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Find APIs on the Public API Directory</h2>
            <p>Discover the perfect API for your application. With powerful search capabilities, you can easily explore and delve into the extensive range of APIs. You can seamlessly integrate the selected APIs using the subscription model and your preferred API type.</p>
            <a href="https://hub.openapihub.com/api-catalogue/all" class="btn btn-primary" target="_blank">Explore All APIs <i class="fas fa-external-link-alt ml-1" /></a>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-consultancy.svg" alt="Search APIs on OpenAPIHub">
          </div>
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Unlock Your API Expertise</h2>
            <p>Explore a wealth of learning resources - articles, videos, and more - empowering developers with API best practices at our educational hub, Open API Academy.</p>
            <a href="https://www.openapihub.com/academy" class="btn btn-primary" target="_blank">Start Learning Now <i class="fas fa-external-link-alt ml-1" /></a>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-education.svg" alt="Open API Academy">
          </div>
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="row align-items-lg-center">
        <div class="col-lg-6 col-xl-6 mb-6 mb-lg-0">
          <h2 class="mb-3">Dive into our API Community Videos</h2>
          <p>Discover a wealth of API knowledge and event highlights on our YouTube channel. Subscribe now for the latest community updates!</p>
          <a href="https://www.youtube.com/@openapihub" class="btn btn-primary" target="_blank">Watch Now <i class="fas fa-external-link-alt ml-1" /></a>

        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/replay-video.svg" alt="Dive into our API Community Videos">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="bg-img-hero position-relative overflow-hidden text-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Ready to Connect With the API Community?</h2>
          <p>Join OpenAPIHub today and start your API journey!</p>
        </div>
        <a class="btn btn-primary transition-3d-hover" href="https://www.openapihub.com/" target="_blank">Join Free Membership <i class="fas fa-external-link-alt ml-1" /></a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Management Platform</h3>
              <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Connectivity for Financial Services</h3>
              <p><strong>FiberConnect</strong> uses Open API to boost SME & Startup efficiency with QR Code payments.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixai' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixai-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>AI Agent Platform</h3>
              <p><strong>FabriXAI</strong> is an AI agent platform for both tech and non-tech users.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>AI Agents for Education</h3>
              <p><strong>TapTab</strong> is the AI agents designed for the education sector.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->
  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'OpenAPIHub',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'OpenAPIHub | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/openapihub' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'OpenAPIHub | Products | beNovelty' },
        { property: 'og:description', content: 'The leading fast-growing Open API Directory and Ecosystem.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/openapihub' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

